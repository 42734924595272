/* ##########################################################
############################################################## */
/* Phần section 1 */
.ct-main {
  width: 100%;
  position: relative;
  align-items: flex-start;
}

.ct-div-black {
  /* width: 43%; */
  background-color: #000;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35);
  box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35); /* Thuộc tính chuẩn */
}

.ct-div-block {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}

#main-content {
  align-self: flex-end;
  margin-right: 2%;
}

#main-content div {
  text-align: justify;
}

.subheading {
  /* font-family: 'Heebo'; */
  font-weight: 700;
  text-align: right;
  line-height: 1;
  letter-spacing: 0.9em;
  margin-top: 50px;
  align-self: flex-end;
  font-size: clamp(10px, 3vw, 33px) !important;
}

#thanh_trang {
  width: 50%;
  right: 0;
  top: 34%;
  height: 7px;
  min-height: 7px;
  border-radius: 2px;
  margin-right: 2%;
  margin-bottom: 50px;
  align-self: flex-end;
  transition: all 1.5s;
  background-color: #fff;
  z-index: 10;
  -webkit-box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35);
  box-shadow: -2px 5px 5px 0 #fff, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35); /* Thuộc tính chuẩn */
}

#thanh_den {
  width: 50%;
  right: 0;
  top: 34%;
  height: 7px;
  min-height: 7px;
  border-radius: 2px;
  margin-right: 2%;
  margin-bottom: 50px;
  align-self: flex-end;
  transition: all 1.5s;
  background-color: #000;
  z-index: 10;
  -webkit-box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35);
  box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35); /* Thuộc tính chuẩn */
}

.heading-main {
  font-size: clamp(30px, 13.5vw, 184px);
  margin: 0;
  align-self: flex-end;
  /* font-family: 'Heebo'; */
  font-weight: 900;
  top: 10%;
  left: 20%;
  letter-spacing: 0.09em;
  line-height: 1;
}

.heading-main,
.blend {
  mix-blend-mode: difference;
  color: #fff;
}

#ct-main-icon {
  text-align: left;
  display: flex;
  margin-top: 20px;
  gap: 25px;
  opacity: 0.5;
}

#ct-main-icon img {
  width: 40px;
  height: auto;
}

/* ############################################################
############################################################ */
/* Phần slide */
.slider-container {
  overflow: hidden;
  /* background-color: #e5e5e5; */
  border-radius: 50px;
  padding: 40px;
  margin-right: 2%;
}

.slider {
  display: flex;
  justify-content: space-around;
  /* Gấp đôi chiều rộng để chứa các bản sao của logo */
  width: calc(100% * 2.5);

  /* Tạo hiệu ứng animation liên tục */
  animation: slide 20s linear infinite;
}

.slider_mobile {
  width: calc(100% * 6);
  animation: slide 30s linear infinite;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

/* ############################################################
############################################################ */
/* AuboutUs của Home */
.img-gal {
  /* height: 555px; */
  /* width: 100%; */
  object-fit: cover;
  transition: 0.5s all ease-in-out;
  border-radius: 20px;
  box-shadow: -2px 5px 5px 0 #000, -2px 5px 5px 0 rgba(0, 0, 0, 0.75),
    -5px 10px 10px 0 rgba(0, 0, 0, 0.75), -15px 15px 15px 0 rgba(0, 0, 0, 0.6),
    -20px 20px 20px 0 rgba(0, 0, 0, 0.6), -30px 30px 30px 0 rgba(0, 0, 0, 0.6),
    -40px 40px 40px 0 rgba(0, 0, 0, 0.35); /* Thuộc tính chuẩn */
}

.img-gallery-container > div:hover img {
  filter: brightness(0.4) blur(3px);
}

.float-gallery-content {
  visibility: hidden;
  transition: 0.3s all ease-in-out;
  overflow: hidden;
}

.img-gallery-container > div:hover .float-gallery-content {
  bottom: 20px;
  opacity: 0.5;
  visibility: visible !important;
  z-index: 9;
}

.img-gallery-container > div:hover .position-main {
  opacity: 0;
  transition: 0.3s all ease-in-out;
}

/* treen oke r */
.highlight {
  font-weight: 700;
  color: #0e3177;
  /* font-weight: 600; */
  position: relative;
  left: -100px;
  opacity: 0;
  transition: all 0.5s;
}

.img-gallery-container > div:hover .highlight {
  left: 0;
  opacity: 1;
  transition-delay: 0.1s;
}

.img-gallery-container > div:hover .content > a {
  bottom: 0;
  opacity: 1;
  transition-delay: 0.1s;
}
