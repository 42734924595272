@font-face {
    font-family: 'Timberline';
    src: url('/public/font/Timberline-Regular.woff2') format('truetype');
    /* Thay đổi đường dẫn tương ứng */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmericanTypewriter';
    src: url('/public/font/AmericanTypewriter.woff') format('truetype');
    /* Thay đổi đường dẫn tương ứng */
    font-weight: normal;
    font-style: normal;
}


/* ############################################################
############################################################ */
/* Phần contacts */
.contacts>h2 {
    font-size: clamp(30px, 13.5vw, 184px);
    transform: rotate(-5deg);
    font-family: 'Timberline';
    letter-spacing: 1px;
    line-height: .9;
    text-align: left;
    max-width: 1000px;
    width: 40vw;
    font-weight: 400;
    margin: 0;
}

/*  */
.form-contacts input {
    border: none !important;
    border-bottom: 2px solid #d1d1d1 !important;
    background: none !important;
    border-radius: 0 !important;
    font-size: 30px !important;
    overflow: visible !important;

    font-family: "AmericanTypewriter" !important;
    font-weight: inherit;
    line-height: 1.4;
    margin: 0;
    min-height: auto;
    max-width: auto;
    padding: 8.5px 10px;
    touch-action: manipulation;
    transition: background-color 200ms ease-in-out, background-image 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
    width: 100% !important;
}
