
.form-container {
    margin: 0 auto;
}

.form-section {
    margin: 20px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

@media (max-width: 1440px) {
    .form-row {
      display: block;
    } 
}

/* @media (max-width: 900px) {
    input[type="text"], input[type="date"], input[type="email"], select {
        width: auto !important;
    }
} */

.form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

label {
    /* font-weight: bold; */
    color: #ccc;
    margin-bottom: 5px;
}

/* input[type="text"], input[type="date"], input[type="email"], select {
    width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
} */

select {
    width: 320px;
}

button {
    background-color: #6e736f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

/* button:hover {
    background-color: #45a049;
} */

p {
    padding-top: 20px;
    /* margin: 0px; */
}

textarea {
    resize: none;
}

canvas {
    width: 100% !important;
    height: 100% !important;
    margin: 20px 0;
}
